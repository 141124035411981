import Vue from 'vue'
import Vuex from 'vuex'

//async await 
import "core-js/stable"; 
import "regenerator-runtime/runtime";

import customers from './store/customer.json'
import {globalfunc} from './shared/GlobalFunction';
import itemServices from './Areas/MasterData/Item/Script/ItemServices.js';


Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  customers: JSON.parse(JSON.stringify(customers)),
  items: null,
  test: [1,2,3]
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  push (state, [variable, value]) {
    state[variable].splice(0,0,value)
  }
}

const actions = {
  // async InitItem(state){
  //   const result = await globalfunc.defaultApolloQueryDefault(itemServices.readQuery());
  //   state.commit("set", ["items",result.data.GetProductMaster.ProductMaster])
  // },
  async setItem(state,variables){
    const result = await globalfunc.defaultApolloQueryDefault(itemServices.readQuery(),variables);
    state.commit("set", ["items",[result.data.GetProductMaster.ProductMaster,result.data.GetProductMaster.Total]])
    return Promise.resolve()
  },
  async AddItem(state, variables){
    const result = await globalfunc.defaultApolloMutationDefault(itemServices.addQuery(), variables)
                  .then(res => {
                      state.commit("push", ["items",variables.inputItem])
                      return res;
                  })
                  .catch(e =>{
                    var errorMessage = globalfunc.ErrorExtractor(e)
                    return errorMessage;
                  });
    return result
  },
}

const getters = {
  getItem(state){
    return state.items;
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})