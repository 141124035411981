import apolloProvider from '../infrastructure/helper/apolloclient';
import axios from 'axios';
import gql from 'graphql-tag';
import VueCookies from 'vue-cookies';
import ProvinceJson from '../assets/jsons/province.json';
import DateTypeJson from '../assets/jsons/datetype.json';
import { apiBaseUrl } from '../infrastructure/constant/connection';
import { baseUrl } from '../infrastructure/constant/connection';
import moment from 'moment';
import { objectConverter } from '../infrastructure/helper/object';

export const globalfunc = {
  ErrorExtractor(errorMessage){
      var deletedString = 'Error: GraphQL error: ';
      var newErrorMessage = errorMessage.toString().replace(deletedString, "").replace("pq: ","");

      if(newErrorMessage == 'access denied'){
        alert('Token Has Been Expired');
        window.location = baseUrl + "/login";
      }
      return newErrorMessage;
  },

  async createErrorLog(variables){
    var query = gql`mutation($data:JSON!){
      CreateErrorLog(errorMessage:$data)
    }`;
    
    await this.defaultApolloMutationDefault(query, variables);
  },

  async permission(){
    var variables = {
      id : window.$cookies.get('RoleID')
    }
    var query = gql`query ($id: Int) {
      GetPerm (RID: $id) {
        mname
        rperms
        cperms
        uperms
        dperms
      }
    }`;

    var data = await this.defaultApolloQueryDefault(query, variables);
    return data.data.GetPerm;
  },

  permissionModule(ModuleId){
    var data = JSON.parse(window.sessionStorage.getItem('PermissionList'));
    var hashMap = [];
    data.forEach(el=>{
      hashMap[el.mname]= el
    })

    var permission = hashMap[ModuleId];
    return permission;
  },

  async getUserInfo(){
    var variables = {
      id : window.$cookies.get('UserID')
    }
    var query = gql`
      query ($id: String) {
        GetUser (UserID: $id) {
          userid
          username
          roleid
          rname
          active_flag
          contact_id
          contact_name
          contact_type
          current_debit
        }
      }
    `;

    var data = await this.defaultApolloQueryDefault(query, variables);
    return data.data.GetUser[0];
  },
  
  async VerifyLogin(){
    var permission = await this.permission();
    window.sessionStorage.setItem('PermissionList', JSON.stringify(permission));

    var data = await axios({
      method: "POST",
      url: apiBaseUrl,
      data: {
        query: `
          query{
            GetModule{
              category
              mname
              moduleid
              url
            }
          }
        `
      },
      headers: {
        authorization: window.$cookies.get('Token'),
        path: window.location.pathname
      }
    }).then((response) => {
        if (response.data.errors){
          return false
        }
        else{
          window.sessionStorage.setItem('ModuleList', JSON.stringify(this.moduleBuilder(response.data.data.GetModule)));
          return true;
        }
    }).catch(() => {
      return false
    })
    
    if(data){
      var userData = await this.getUserInfo();
      window.$cookies.set('CurrentDebit', (userData.current_debit == null ? '0' : kendo.toString(userData.current_debit, 'n0')));
    }

    return data
  },

  moduleBuilder(data){
    const moduleJson = [];

    var dashboard =  {_name: "CSidebarNavItem",
                      name: "Dashboard",
                      to: "/dashboard",
                      icon: "cil-home"};
    moduleJson.push(dashboard);

    if(data != null){
      var module = this.moduleConvert(data);
      
      var moduleParentDetail = [{ index: "MasterData", name: "Master Data", route: "/masterdata", icon: "cil-layers" },
                                { index: "Transaction", name: "Transaction", route: "/transaction", icon: "cil-basket" },
                                { index: "Inventory", name: "Inventory", route: "/inventory", icon: "cil-storage" },
                                { index: "ProjectManagement", name: "Project Management", route: "/project-management", icon: "cil-list" },
                                { index: "Commission", name: "Komisi", route: "/commission", icon: "cil-list" },
                                { index: "Production", name: "Production", route: "/production", icon: "cil-factory" },
                                { index: "HumanResource", name: "Human Resource", route: "/human-resource", icon: "cil-people" },
                                { index: "Accounting", name: "Accounting", route: "/accounting", icon: "cil-calculator" },
                                { index: "Finance", name: "Finance", route: "/finance", icon: "cil-dollar" },
                                { index: "Purchasing", name: "Purchasing", route: "/purchasing", icon: "cil-cart" },
                                { index: "CRM", name: "CRM", route: "/crm", icon: "cil-view-column" },
                                { index: "Reporting", name: "Reporting", route: "/reporting", icon: "cil-file" },
                                { index: "TechnicalSupport", name: "Technical Support", route: "/technical-support", icon: "cil-headphones" },
                                { index: "Administration", name: "Administration", route: "/administration", icon: "cil-cog" },
                              ];  
        
      moduleParentDetail.forEach(el=>{
        var item = module[el.index];

        if(item != undefined){
          var items = [];
          for(let i = 0; i < item.length; i++){
            var itemStr = { name: item[i].mname, to: item[i].url }
            items.push(itemStr);
          };

          var str = { _name: "CSidebarNavDropdown",
                      name: el.name,
                      to: el.route,
                      icon: el.icon,
                      items: items }
          moduleJson.push(str);
        }
      });
    };

    return moduleJson;
  },
  
  moduleConvert(data) {
    var res = {}
    data.forEach(el => {
      if (res[el.category] !== undefined) {
        res[el.category].push(el)
      } else {
        res[el.category] = [el]
      }
    });

    return res
  },

  async defaultApolloQueryDefault(query, variables){
    const result = await apolloProvider.defaultClient.query({
      query : query,
      variables : variables,
      context : {
        headers : {
          authorization: VueCookies.get('Token') || '',
          path: window.location.pathname
        }
      }
    }).then(res=>{
      return res
    }).catch(err=>{
      var variable = {
        data : {
          user_login : window.$cookies.get('Name'),
          query : query.loc.source.body,
          variable : variables,
          header : {
            // authorization: VueCookies.get('Token') || '',
            path: window.location.pathname
          },
          error_message : err.networkError.result.errors[0].message
        }
      }
      
      this.createErrorLog(variable)
      return err 
    })

    return result
  },

  async defaultApolloMutationDefault(query, variables){
    const result = await apolloProvider.defaultClient.mutate({
      mutation : query,
      variables : variables,
      context : {
        headers : {
          authorization: VueCookies.get('Token') || '',
          path: window.location.pathname
        }
      }
    }).then(res=>{
      return res
    }).catch(err=>{
      var deletedString = 'Error: GraphQL error: ';
      var newErrorMessage = err.toString().replace(deletedString, "");
      var variable = {
        data : {
          user_login : window.$cookies.get('Name'),
          query : query.loc.source.body,
          variable : variables,
          header : {
            // authorization: VueCookies.get('Token') || '',
            path: window.location.pathname
          },
          error_message : newErrorMessage
        }
      }
      
      this.createErrorLog(variable)
      throw err 
    })

    return result
  },

  operatorHandler(el){
    switch(el.operator){
      case "contains":
        return `lower(`+el.field+`) like lower('%`+el.value+`%')`
      case "isnotempty":
        return `(`+el.field+` is not null or `+el.field+` not like '')`
      case "isempty":
        return `(`+el.field+` is null or `+el.field+`  like '')`
      case "lt":
        return `(`+el.field+` < `+el.value+`)`
      case "gt":
        return `(`+el.field+` > `+el.value+`)`
      case "eq":
        return `(`+el.field+` = `+el.value+`)`
      case "gte":
        return `(`+el.field+` >= `+el.value+`)`
      case "lte":
        return `(`+el.field+` <= `+el.value+`)`
      case "gtd":
        return `(`+el.field+` >= '`+ moment(el.value).format("YYYY-MM-DD") +`')`
      case "ltd":
        return `(`+el.field+` <= '`+ moment(el.value).format("YYYY-MM-DD") +`')`
    }
  },

  filterQueryBuilder(filter, swapField, ignoredField){
    if(filter === undefined || filter == null){
      return null;
    } 

    var result  = ''
    var isFirst = true

    filter.filters.forEach(el=>{
      if(ignoredField !== undefined){
        var data = ignoredField.find(x => x === el.field);
        if(data === undefined){
          if(!isFirst){
            result=result+ filter.logic+ ` `
          }
          else{
            isFirst=false
          }

          if(swapField !== undefined){
            var data = swapField.find(x => x.field === el.field);
            if(data !== undefined){
              el.field = data.new_field;
            }
          }
          
          if(el.filters){
            result = this.filterQueryBuilder(el,result)
          }
          else{
            result = result + this.operatorHandler(el) + ` `
          }
          
        }
      }else{
        if(!isFirst){
          result=result+ filter.logic+ ` `
        }
        else{
          isFirst=false
        }
  
        if(swapField !== undefined){
          var data = swapField.find(x => x.field === el.field);
          if(data !== undefined){
            el.field = data.new_field;
          }
        }
      
        if(el.filters){
          result = this.filterQueryBuilder(el,result)
        }
        else{
          result = result + this.operatorHandler(el) + ` `
        }
      }
    })
    
    if(result !== ""){
      result = '(' + result + ')';
    }else{
      result = null;
    }
    
    return result
  },

  havingQueryBuilder(filter, swapField, ignoredField){
    if(filter === undefined || filter == null){
      return null;
    }

    var result  = ''
    var isFirst = true

    filter.filters.forEach(el=>{
      if(ignoredField !== undefined){
        var data = ignoredField.find(x => x === el.field);
        if(data !== undefined){
          if(!isFirst){
            result=result+ filter.logic+ ` `
          }
          else{
            isFirst=false
          }

          if(swapField !== undefined){
            var data = swapField.find(x => x.field === el.field);
            if(data !== undefined){
              el.field = data.new_field;
            }
          }
          
          if(el.filters){
            result = this.havingQueryBuilder(el,result)
          }
          else{
            result = result + this.operatorHandler(el) + ` `
          }
        }
      }
    })
    if(result !== ""){
      result = '(' + result + ')';
    }else{
      result = null;
    }
    
    return result
  },

  sortingQueryBuilder(sorter, swapField){
    if(sorter === undefined || sorter == null || sorter.length == 0){
      return null;
    }

    var field = sorter[0].field;

    if(swapField !== undefined){
      var data = swapField.find(x => x.field === sorter[0].field);
      if(data !== undefined){
        field = data.new_field;
      }
    }
    
    return field + ' ' + sorter[0].dir;
  },

  gridActionButton(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var deleteString = permission.dperms ? "" : "disabled";
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-primary btn-sm rounded" id="EditButton" `+ updateString +`>
                <i class="fa fa-edit"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-danger btn-sm rounded" id="DeleteButton" `+ deleteString +`>
                <i class="fa fa-trash"></i> </span>
            </button>
            `+ customButton +`
        </div>
    `
  },

  gridActionWithStatusChangesButton(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var deleteString = permission.dperms ? "" : "disabled";
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-primary btn-sm rounded" id="EditButton" `+ updateString +`>
                <i class="fa fa-edit"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-danger btn-sm rounded" id="DeleteButton" `+ deleteString +`>
                <i class="fa fa-trash"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-success btn-sm rounded" id="StatusButton" `+ updateString +`>
                <i class="fa fa-check"></i> </span>
            </button>
            `+ customButton +`
        </div>
    `
  },

  gridActionWithoutDeleteButton(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-primary btn-sm rounded" id="EditButton" `+ updateString +`>
                <i class="fa fa-edit"></i> </span>
            </button>&nbsp;&nbsp;
            `+ customButton +`
        </div>
    `
  },

  gridActionButtonViewOnly(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>            
            `+ customButton +`
        </div>
    `
  },
  
  gridActionApproveButton(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var viewString = permission.rperms ? "" : "disabled";
    var updateString = permission.uperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
              <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" `+ updateString +`>
              <i class="fa fa-check"></i> </span>
            </button>&nbsp;&nbsp;
            `+ customButton +`
        </div>
    `
  },
  
  gridActionButtonBookingOrderOnly(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var deleteString = permission.dperms ? "" : "disabled";
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-success btn-sm rounded" id="EditButton" `+ updateString +`>
                <i class="fa fa-save"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-danger btn-sm rounded" id="DeleteButton" `+ deleteString +`>
                <i class="fa fa-trash"></i> </span>
            </button>
            `+ customButton +`
        </div>
    `
  },

  gridActionStatusButtonWithoutDelete(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var viewString = permission.rperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
            <button type="button" class="btn btn-info btn-sm rounded" id="ViewButton" `+ viewString +`>
                <i class="fa fa-eye"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-primary btn-sm rounded" id="EditButton" `+ updateString +`>
                <i class="fa fa-edit"></i> </span>
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-success btn-sm rounded" id="StatusButton" `+ updateString +`>
                <i class="fa fa-check"></i> </span>
            </button>
            `+ customButton +`
        </div>
    `
  },
  
  gridActionButtonStatusOnly(ModuleId, CustomButton){
    var permission = this.permissionModule(ModuleId);
    var updateString = permission.uperms ? "" : "disabled";
    var customButton = '';
    if(CustomButton != undefined){
      customButton = CustomButton;
    }
    return `
        <div class="btn-group">
          <button type="button" class="btn btn-success btn-sm rounded" id="StatusButton" `+ updateString +`>
              <i class="fa fa-check"></i> </span>
          </button>
            `+ customButton +`
        </div>
    `
  },

  gridFileViewButton(file){
    if(file == null || file == ''){
        return '';
    }else{
        return `<button type="button" class="btn btn-secondary btn-sm w-100" id="FileButton" >
                  View
                </button>`;
    }
  },

  objectToArrayConverter(data, module){
    //data must be object
    let array = [];

    if(data.length > 0){
      for (let i = 0; i < data.length; i++) {
        var str = objectConverter(data[i], module);

        if(str == null){
          var variable = {
            data : {
              user_login : window.$cookies.get('Name'),
              header : {
                // authorization: VueCookies.get('Token') || '',
                path: window.location.pathname
              },
              module_name : module,
              error_message : "Object To Array Converter Module Name Not Match"
            }
          }

          this.createErrorLog(variable)
          break;
        }else{
          array.push(str);
        }
      }
    }
    
    return array;
  },
  
  async globalDropdown(name){
    var variables = {
      name : name,
      allowCode : parseInt(window.$cookies.get('RoleID'))
    }

    var query = gql`query($name:String,$allowCode:Int){
        GetDropdownList(DDLName:$name,Allowed:$allowCode){
          ddl
        }
    }`;
    var result = await this.defaultApolloQueryDefault(query, variables);
    var ddlArray = [];
    if(result.data.GetDropdownList != null){
      ddlArray = this.objectToArrayConverter(result.data.GetDropdownList, "Global-StatusDropDownList")
    }else{
      ddlArray = [{ value: '', label: ''}];
    }
    
    return ddlArray;
  },

  async globalTypeLookupDropdown(type){
    var variables = {
      type : type,
    }

    var query = gql`query($type:String){
        GetTypeLookup(TypeCategory:$type){
          type_id
          type_name
          type_category
        }
    }`;
    
    var result = await this.defaultApolloQueryDefault(query, variables);
    var ddlArray = [];
    if(result.data.GetTypeLookup != null){
      ddlArray = this.objectToArrayConverter(result.data.GetTypeLookup, "Global-TypeLookupDropDownList")
    }else{
      ddlArray = [];
    }
    
    return ddlArray;
  },

  async globalStatusLookupDropdown(type){
    var variables = {
      type : type,
    }

    var query = gql`query($type:String){
      GetStatusLookup(StatusCategory:$type){
          status_id
          status_name
          status_category
        }
    }`;
    
    var result = await this.defaultApolloQueryDefault(query, variables);
    var ddlArray = [];
    if(result.data.GetStatusLookup != null){
      ddlArray = this.objectToArrayConverter(result.data.GetStatusLookup, "Global-StatusLookupDropDownList")
    }else{
      ddlArray = [];
    }
    
    return ddlArray;
  },
  
  async getPPNTax(){
    var query = gql`query{
        GetMasterTax(isDefault:true){
            percentage
        }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query);
    return {label:result.data.GetMasterTax[0].percentage, value:result.data.GetMasterTax[0].percentage/100};
  },

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  },

  async dynamicStatusButton(){
    var query = gql`query{
      GetDataCount{
        status
        count
      }
    }`;
    
    var result = await this.defaultApolloQueryDefault(query);
    var data = result.data.GetDataCount;
    
    var totalAll = this.getSum(data, 'count');

    var start = '<div class="btn-group w-100">',
        main = '<button id="btnAll" style="width:12%" class="btn btn-secondary btn-All">All&nbsp;<b>'+totalAll.toString()+'</b></button>&nbsp;',
        end = '</div><hr>';

    if(data != null){
      for (let i = 0; i < data.length; i++){
        var str = '<button id="btn' + data[i].status.toString().replace(/\s/g, '') + '" style="width:12%" class="btn btn-secondary btn-'+ data[i].status.toString().replace(/\s/g, '') +'" >' + data[i].status.toString() + '&nbsp;<b>'+ data[i].count.toString() +'</b></button>&nbsp;';
        main = main + str;
      }
    }

    return (start + main + end);
  },

  getSum(array, column){
    if(array == null){
      return 0;
    }else{
      let values = array.map((item) => parseInt(item[column]) || 0)
      return values.reduce((a, b) => a + b);
    }
  },

  getCountryData(Province){
    let countryOnProvinceData = ProvinceJson.find(data => data.provinsi == Province);
    return countryOnProvinceData.negara;
  },

  getProvinceData(){
    var provinceData = [];
    
    for (let i = 0; i < ProvinceJson.length; i++) {
      var str = {value : ProvinceJson[i].provinsi, 
                  label : ProvinceJson[i].provinsi}
      provinceData.push(str);
    }

    return provinceData
  },

  getCityData(Province){
    var cityData = [];
    let cityOnProvinceData = ProvinceJson.find(data => data.provinsi == Province);
    
    for (let i = 0; i < cityOnProvinceData.kota.length; i++) {
      var str = { value : cityOnProvinceData.kota[i], 
                  label : cityOnProvinceData.kota[i]}
      cityData.push(str);
    }

    return cityData
  },

  getDateTypeData(){
    var dateTypeData = [];
    
    for (let i = 0; i < DateTypeJson.length; i++) {
      var str = {value : DateTypeJson[i].datetype, 
                  label : DateTypeJson[i].datetype}
      dateTypeData.push(str);
    }

    return dateTypeData
  },

  getDateTypeDetail(dateType){
    var dateFrom = moment(new Date()).startOf('day').format("YYYY-MM-DD");
    var dateTo = moment(new Date()).endOf('day').format("YYYY-MM-DD");

    if(dateType == 'Yesterday'){
      dateFrom = moment().subtract(1, 'days').format("YYYY-MM-DD"); 
      dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD"); 
    }
    else if(dateType == 'Last 7 Days'){
      dateFrom = moment().subtract(7, 'days').format("YYYY-MM-DD"); 
    }
    else if(dateType == 'Last Week (Mon - Sun)'){
      dateFrom = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      dateTo = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
    }
    else if(dateType == 'Month to Date'){
      dateFrom = moment().startOf('month').format('YYYY-MM-DD');
    }
    else if(dateType == 'This Month'){
      dateFrom = moment().startOf('month').format('YYYY-MM-DD');
      dateTo = moment().endOf('month').format('YYYY-MM-DD');
    }
    else if(dateType == 'Previous Month'){
      dateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      dateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    }
    else if(dateType == 'Year to Date'){
      dateFrom = moment().startOf('year').format('YYYY-MM-DD');
    }
    else if(dateType == 'Previous Year'){
      dateFrom = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
      dateTo = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
    }
    else {
      dateFrom = null;
      dateTo = null;
    }

    var data = {
      startDate: dateFrom,
      endDate: dateTo
    };

    return data;
  },

  percentValidate(input){
    var result = input;
    if(input == "" || input == null){
      result = 0;
    }else{
      if(input > 100){
        result = 100;
      }
      else if(input < 0){
        result = 0;
      }
      else{
        result = input;
      }
    };

    return result;
  }
}