import Vue from 'vue'
import Router from 'vue-router'
import { baseUrl } from '../infrastructure/constant/connection';

// Containers
const TheContainer = () => import(/* webpackChunkName: "TheContainer" */'@/containers/TheContainer')

// Views
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */'../Areas/Core/Dashboard/Dashboard.vue')

// Core
const Login = () => import(/* webpackChunkName: "Login" */'../Areas/Core/Account/Login.vue')
const User = () => import(/* webpackChunkName: "User" */'../Areas/Core/User/User.vue')
const Role = () => import(/* webpackChunkName: "Role" */'../Areas/Core/Role/Role.vue')

//CRM
const CRMProject = () => import(/* webpackChunkName: "CRM" */'../Areas/CRM/CRMProject/CRMProject.vue')
const CRMRetail = () => import(/* webpackChunkName: "CRM" */'../Areas/CRM/CRMRetail/CRMRetail.vue')

//MasterData
const Storage = () => import(/* webpackChunkName: "Storage" */'../Areas/MasterData/Storage/Storage.vue')
const Item = () => import(/* webpackChunkName: "Item" */'../Areas/MasterData/Item/Item.vue')
const Machine = () => import(/* webpackChunkName: "Machine" */'../Areas/MasterData/Machine/Machine.vue')
const Bank = () => import(/* webpackChunkName: "Bank" */'../Areas/MasterData/Bank/Bank.vue')
const Region = () => import(/* webpackChunkName: "Region" */'../Areas/MasterData/Region/Region.vue')
const Currency = () => import(/* webpackChunkName: "Currency" */'../Areas/MasterData/Currency/Currency.vue')
const Store = () => import(/* webpackChunkName: "Store" */'../Areas/MasterData/Store/Store.vue')
const ItemGroup = () => import(/* webpackChunkName: "ItemGroup" */'../Areas/MasterData/ItemGroup/ItemGroup.vue')
const Price = () => import(/* webpackChunkName: "Price" */'../Areas/MasterData/Price/Price.vue')
const GroupType = () => import(/* webpackChunkName: "GroupType" */'../Areas/MasterData/GroupType/GroupType.vue')
const TypeDetail = () => import(/* webpackChunkName: "TypeDetail" */'../Areas/MasterData/TypeDetail/TypeDetail.vue')
const AreaCode = () => import(/* webpackChunkName: "AreaCode" */'../Areas/MasterData/AreaCode/AreaCode.vue')
const PpnTax = () => import(/* webpackChunkName: "PpnTax" */'../Areas/MasterData/PpnTax/PpnTax.vue')
const Vendor = () => import(/* webpackChunkName: "Vendor" */'../Areas/MasterData/Vendor/Vendor.vue')
const Vehicle = () => import(/* webpackChunkName: "Vehicle" */'../Areas/MasterData/Vehicle/Vehicle.vue')
const TaxInvoice = () => import(/* webpackChunkName: "TaxInvoice" */'../Areas/MasterData/TaxInvoice/TaxInvoice.vue')

//Human Resource
const EmployeeHR = () => import(/* webpackChunkName: "HumanResource" */'../Areas/HumanResource/EmployeeHR/EmployeeHR.vue')
const Attendance = () => import(/* webpackChunkName: "HumanResource" */'../Areas/HumanResource/Attendance/Attendance.vue')
const Payroll = () => import(/* webpackChunkName: "HumanResource" */'../Areas/HumanResource/Payroll/Payroll.vue')
const WarningLetter = () => import(/* webpackChunkName: "HumanResource" */'../Areas/HumanResource/WarningLetter/WarningLetter.vue')

//Contact
const Distributor = () => import(/* webpackChunkName: "Contact" */'../Areas/MasterData/Distibutor/Distributor.vue')
const Employee = () => import(/* webpackChunkName: "Contact" */'../Areas/MasterData/Employee/Employee.vue')
const Afiliation = () => import(/* webpackChunkName: "Contact" */'../Areas/MasterData/Afiliation/Afiliation.vue')
const Expedition = () => import(/* webpackChunkName: "Contact" */'../Areas/MasterData/Expedition/Expedition.vue')
const Driver = () => import(/* webpackChunkName: "Contact" */'../Areas/MasterData/Driver/Driver.vue')

//Inventory
const Stock = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/Stock/Stock.vue')
const MutasiStock = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/MutasiStock/MutasiStock.vue')
const StockSales = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/StockSales/StockSales.vue')
const PurchaseItemPrice = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/PurchaseItemPrice/PurchaseItemPrice.vue')
const ItemTransfer = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/ItemTransfer/ItemTransfer.vue')
const MinimalStockItem = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/MinimalStockItem/MinimalStockItem.vue')
const ForecastingStock = () => import(/* webpackChunkName: "Inventory" */'../Areas/Inventory/ForecastingStock/ForecastingStock.vue')

//Project Management
const PraProject = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/PraProject/PraProject.vue')
const Project = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/Project/Project.vue')
const ProjectFormPage = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/Project/Component/ProjectFormPage.vue')
const Retail = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/Retail/Retail.vue')
const RetailFormPage = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/Retail/Component/RetailFormPage.vue')
const PRM = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/PRM/PRM.vue')
// const Commission = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement//Commission/Commission.vue')
const PraProjectApprover = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/PraProjectApprover/PraProjectApprover.vue')
const BorrowingTools = () => import(/* webpackChunkName: "ProjectManagement" */'../Areas/ProjectManagement/BorrowingTools/BorrowingTools.vue')
const BorrowingToolsDetail = () => import(/* webpackChunkName: "Transaction" */'../Areas/ProjectManagement/BorrowingTools/Component/BorrowingToolsForm.vue')

//Reporting
const ProductionReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/ProductionReport/ProductionReport.vue')
const TrialBalanceReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TrialBalanceReport/TrialBalanceReport.vue')
const TrialBalanceReportGrid = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TrialBalanceReport/Grid/TrialBalanceReportGrid.vue')
const LedgerReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/LedgerReport/LedgerReport.vue')
const PurchasingRequestReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/PurchasingRequestReport/PurchasingRequestReport.vue')
const PurchasingOrderReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/PurchasingOrderReport/PurchasingOrderReport.vue')
const PurchasingInvoiceReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/PurchasingInvoiceReport/PurchasingInvoiceReport.vue')
const PurchasingInvoicePaymentReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/PurchasingInvoicePaymentReport/PurchasingInvoicePaymentReport.vue')
const PurchasingDeliveryOrderReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/PurchasingDeliveryOrderReport/PurchasingDeliveryOrderReport.vue')
const TransactionPurchaseOrderReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TransactionPurchaseOrderReport/TransactionPurchaseOrderReport.vue')
const TransactionDeliveryOrderReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TransactionDeliveryOrderReport/TransactionDeliveryOrderReport.vue')
const TransactionInvoiceReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TransactionInvoiceReport/TransactionInvoiceReport.vue')
const TransactionInvoicePaymentReport= () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/TransactionInvoicePaymentReport/TransactionInvoicePaymentReport.vue')
const DebtReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/DebtsReport/DebtsReport.vue')
const ReceivableReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/ReceivablesReport/ReceivablesReport.vue')
const ForecastReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/ForecastReport/ForecastReport.vue')
const ForecastOtherReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/ForecastOtherReport/ForecastOtherReport.vue')
const BookingOrderReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/BookingOrderReport/BookingOrderReport.vue')
const SalesReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/SalesReport/SalesReport.vue')
const MutasiStockReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/MutasiStockReport/MutasiStockReport.vue')
const ItemFirstInFirstOutMutationReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/ItemFirstInFirstOutMutationReport/ItemFirstInFirstOutMutationReport.vue')
const CommissionReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/CommissionReport/CommissionReport.vue')
const BorrowingToolsReport = () => import(/* webpackChunkName: "Reporting" */'../Areas/Reporting/BorrowingToolsReport/BorrowingToolsReport.vue')

//Transaction
const PurchaseOrder = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/PurchaseOrder/PurchaseOrder.vue')
const PurchaseOrderFormPage = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/PurchaseOrder/Component/PurchaseOrderFormPage.vue')
const ProformaInvoiceDownPayment = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/ProformaInvoiceDownPayment/ProformaInvoiceDownPayment.vue')
const ProformaInvoiceDownPaymentFormPage = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/ProformaInvoiceDownPayment/Component/ProformaInvoiceDownPaymentFormPage.vue')
const DeliveryOrder = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/DeliveryOrder/DeliveryOrder.vue')
const DeliveryOrderDetail = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/DeliveryOrder/Component/DeliveryOrderForm.vue')
const Invoice = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/Invoice/Invoice.vue')
const InvoiceForm = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/Invoice/Component/InvoiceForm.vue')
const PaymentInvoice = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/PaymentInvoice/PaymentInvoice.vue')
const PaymentInvoiceForm = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/PaymentInvoice/Component/PaymentInvoiceForm.vue')
const MutasiSaldo = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/MutasiSaldo/MutasiSaldo.vue')
// const InvoiceDebit = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/InvoiceDebit/InvoiceDebit.vue')
// const InvoiceDebitForm = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/InvoiceDebit/Component/InvoiceDebitForm.vue')
const FinanceApprover = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/FinanceApprover/FinanceApprover.vue')
const TrackingPurchaseOrder = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/TrackingPurchaseOrder/TrackingPurchaseOrder.vue')
const BookingOrder = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/BookingOrder/BookingOrder.vue')
const BookingPurchaseOrderFormPage = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/BookingOrder/Component/BookingPurchaseOrderFormPage.vue')
const BookingOrderDistributor = () => import(/* webpackChunkName: "Transaction" */'../Areas/Transaction/BookingOrderDistributor/BookingOrderDistributor.vue')

//Production
const ProductionCard = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionCard/ProductionCard.vue')
const ProductionCardForm = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionCard/Component/ProductionCardForm.vue')
const Formula = () => import(/* webpackChunkName: "Production" */'../Areas/Production/Formula/Formula.vue')
const ProductionDeliveryOrder = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionDeliveryOrder/ProductionDeliveryOrder.vue')
const ProductionDeliveryOrderForm = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionDeliveryOrder/Component/ProductionDeliveryOrderForm.vue')
const ProductionInvoice = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionInvoice/ProductionInvoice.vue')
const ProductionInvoiceForm = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionInvoice/Component/ProductionInvoiceForm.vue')
const ProductionInvoicePayment = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionInvoicePayment/ProductionInvoicePayment.vue')
const ProductionInvoicePaymentForm = () => import(/* webpackChunkName: "Production" */'../Areas/Production/ProductionInvoicePayment/Component/ProductionInvoicePaymentForm.vue')
const RequestMaterial = () => import(/* webpackChunkName: "Production" */'../Areas/Production/RequestMaterial/RequestMaterial.vue')

//Accounting
const COA = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/COA/Coa.vue')
const TrialBalance = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/TrialBalance/TrialBalance.vue')
const TrialBalanceGrid = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/TrialBalance/Grid/TrialBalanceGrid.vue')
const Ledger = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/Ledger/Ledger.vue')
const Balance = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/Balance/Balance.vue')
const BalanceGrid = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/Balance/Grid/BalanceGrid.vue')
const GeneralLedger = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/GeneralLedger/GeneralLedger.vue')
const Asset = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/Asset/Asset.vue')
const AssetDepreciation = () => import(/* webpackChunkName: "Accounting" */'../Areas/Accounting/AssetDepreciation/AssetDepreciation.vue')

//Finance
const BankIn = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/BankIn/BankIn.vue')
const BankInForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/BankIn/Component/BankInForm.vue')
const BankOut = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/BankOut/BankOut.vue')
const BankOutForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/BankOut/Component/BankOutForm.vue')
const CashIn = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/CashIn/CashIn.vue')
const CashInForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/CashIn/Component/CashInForm.vue')
const CashOut = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/CashOut/CashOut.vue')
const CashOutForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/CashOut/Component/CashOutForm.vue')
const GeneralJournal = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/GeneralJournal/GeneralJournal.vue')
const GeneralJournalForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/GeneralJournal/Component/GeneralJournalForm.vue')
const AdjustmentJournal = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/AdjusmentJournal/AdjustmentJournal.vue')
const AdjustmentJournalForm = () => import(/* webpackChunkName: "Finance" */'../Areas/Finance/AdjusmentJournal/Component/AdjustmentJournalForm.vue')

//Purchasing
const Request = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/Request/Request.vue')
const RequestForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/Request/Component/RequestForm.vue')
const RequestOrder = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/RequestOrder/RequestOrder.vue')
const RequestOrderForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/RequestOrder/Component/RequestOrderForm.vue')
// const PurchasingPurchaseOrder = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingPurchaseOrder/PurchasingPurchaseOrder.vue')
// const PurchasingPurchaseOrderForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingPurchaseOrder/Component/PurchasingPOFormPage.vue')
const PurchasingInvoice = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingInvoice/PurchasingInvoice.vue')
const PurchasingInvoiceForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingInvoice/Component/PurchasingInvoiceForm.vue')
const PurchasingPaymentInvoice = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingPaymentInvoice/PurchasingPaymentInvoice.vue')
const PurchasingPaymentInvoiceForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/PurchasingPaymentInvoice/Component/PurchasingPaymentInvoiceForm.vue')
const Delivery = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/Delivery/Delivery.vue')
const DeliveryForm = () => import(/* webpackChunkName: "Purchasing" */'../Areas/Purchasing/Delivery/Component/DeliveryForm.vue')

//Technical Support
const TechnicalSupportRequest = () => import(/* webpackChunkName: "TechnicalSupport" */'../Areas/TechnicalSupport/TechnicalSupportRequest/TechnicalSupportRequest.vue')
const TechnicalSupportVisit = () => import(/* webpackChunkName: "TechnicalSupport" */'../Areas/TechnicalSupport/TechnicalSupportVisit/TechnicalSupportVisit.vue')
const TechnicalSupportVisitForm = () => import(/* webpackChunkName: "TechnicalSupport" */'../Areas/TechnicalSupport/TechnicalSupportVisit/Component/TechnicalSupportVisitForm.vue')
const TechnicalSupportComplain = () => import(/* webpackChunkName: "TechnicalSupport" */'../Areas/TechnicalSupport/TechnicalSupportComplain/TechnicalSupportComplain.vue')
const TechnicalSupportComplainForm = () => import(/* webpackChunkName: "TechnicalSupport" */'../Areas/TechnicalSupport/TechnicalSupportComplain/Component/TechnicalSupportComplainForm.vue')

//Komisi
const CommissionEstimation = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/CommissionEstimation/CommissionEstimation.vue')
const CommissionEstimationForm = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/CommissionEstimation/Component/CommissionEstimationForm.vue')
const CommissionSpec = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/CommissionSpec/CommissionSpec.vue')
const CommissionSpecForm = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/CommissionSpec/Component/CommissionSpecForm.vue')
const Commission = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/Commission/Commission.vue')
const CommissionForm = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/Commission/Component/CommissionForm.vue')
const CommissionPayment = () => import(/* webpackChunkName: "Commission" */'../Areas/Commission/CommissionPayment/CommissionPayment.vue')

//HTML Template
const AdjustmentJournalHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/AdjustmentJournalHtmlTemplate.vue')
const GeneralJournalHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/GeneralJournalHtmlTemplate.vue')
const BankInHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/BankInHtmlTemplate.vue')
const BankOutHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/BankOutHtmlTemplate.vue')
const CashInHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/CashInHtmlTemplate.vue')
const CashOutHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/CashOutHtmlTemplate.vue')
const DeliveryOrderHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/DeliveryOrderHtmlTemplate.vue')
const LedgerHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/LedgerHtmlTemplate.vue')
const PurchaseOrderHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchaseOrderHtmlTemplate.vue')
const LedgerReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/LedgerReportHtmlTemplate.vue')
const InvoiceHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/InvoiceHtmlTemplate.vue')
const BalanceHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/BalanceHtmlTemplate.vue')
const TrialBalanceHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TrialBalanceHtmlTemplate.vue')
const ProductionReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/ProductionReportHtmlTemplate.vue')
const PurchasingDeliveryOrderReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchasingDeliveryOrderReportHtmlTemplate.vue')
const PurchasingInvoicePaymentReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchasingInvoicePaymentReportHtmlTemplate.vue')
const PurchasingInvoiceReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchasingInvoiceReportHtmlTemplate.vue')
const PurchasingOrderReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchasingOrderReportHtmlTemplate.vue')
const PurchasingRequestReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/PurchasingRequestReportHtmlTemplate.vue')
const TrialBalanceReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TrialBalanceReportHtmlTemplate.vue')
const TransactionPurchaseOrderReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TransactionPurchaseOrderReportHtmlTemplate.vue')
const TransactionInvoiceReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TransactionInvoiceReportHtmlTemplate.vue')
const TransactionInvoicePaymentReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TransactionInvoicePaymentReportHtmlTemplate.vue')
const TransactionDeliveryOrderReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/TransactionDeliveryOrderReportHtmlTemplate.vue')
const GeneralLedgerHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/GeneralLedgerHtmlTemplate.vue')
const RequestOrderHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/RequestOrderHtmlTemplate.vue')
const RequestOrderHtmlEnglishTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/RequestOrderHtmlTemplateEnglish.vue')
const ForecastingStockHtml = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/ForecastingStockHtmlTemplate.vue')
const FinanceApproverHtml = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/FinanceApproverHtmlTemplate.vue')
const SalesReportHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/SalesReportHtmlTemplate.vue')
const ProformaInvoiceHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/ProformaInvoiceHtmlTemplate.vue')
const ProformaInvoiceBookingHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/ProformaInvoiceBookingHtmlTemplate.vue')
const ProformaInvoiceDownPaymentHtmlTemplate = () => import(/* webpackChunkName: "HTMLTemplate" */'../assets/html/ProformaInvoiceDownPaymentHtmlTemplate.vue')

import {globalfunc} from '../shared/GlobalFunction'

Vue.use(Router)

var router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  // base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  document.title = to.name ?? 'Westpex Pipa Modern'
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(window.$cookies.get('Token') == null){
      if(window.location.href != (baseUrl + "/")){
        alert('Token Has Been Expired'); 
      }
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
    else{
      globalfunc.VerifyLogin().then((response) =>{
        if(response){
          next()
        }
        else{
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        }
      })
    }
  } else{
    next()
  }
})


function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/user',
          name: 'User',
          component: User
        },
        {
          path: '/role',
          name: 'Role',
          component: Role
        },
        {
          path: '/crm-retail',
          name: 'CRM Retail',
          component: CRMRetail
        },
        {
          path: '/crm-project',
          name: 'CRM Project',
          component: CRMProject
        },
        {
          path: '/bank',
          name: 'Bank',
          component: Bank
        },
        {
          path: '/storage',
          name: 'Storage',
          component: Storage
        },
        {
          path: '/item',
          name: 'Item',
          component: Item
        },
        {
          path: '/machine',
          name: 'Machine',
          component: Machine
        },
        {
          path: '/vehicle',
          name: 'Vehicle',
          component: Vehicle
        },
        {
          path: '/asset',
          name: 'Aktifa',
          component: Asset
        },
        {
          path: '/asset-depreciation',
          name: 'Depresiasi Aktifa',
          component: AssetDepreciation
        },
        {
          path: '/currency',
          name: 'Currency',
          component: Currency
        },
        {
          path: '/employee-hr',
          name: 'Employee HR',
          component: EmployeeHR
        },
        {
          path: '/attendance',
          name: 'Attendance',
          component: Attendance
        },
        {
          path: '/payroll',
          name: 'Payroll',
          component: Payroll
        },
        {
          path: '/warning-letter',
          name: 'Warning Letter',
          component: WarningLetter
        },
        {
          path: '/region',
          name: 'Region',
          component: Region
        },
        
        {
          path: '/distributor',
          name: 'Distributor',
          component: Distributor
        },
        {
          path: '/pricing',
          name: 'Price',
          component: Price
        },
        {
          path: '/store',
          name: 'Store',
          component: Store
        },
        {
          path: '/item-group',
          name: 'Item Group',
          component: ItemGroup
        },
        {
          path: '/employee',
          name: 'Employee',
          component: Employee
        },
        {
          path: '/afiliation',
          name: 'Afiliation',
          component: Afiliation
        },
        {
          path: '/group-type',
          name: 'Group Type',
          component: GroupType
        },
        {
          path: '/expedition',
          name: 'Expedition',
          component: Expedition
        },
        {
          path: '/driver',
          name: 'Driver',
          component: Driver
        },
        {
          path: '/type-detail',
          name: 'Type Detail',
          component: TypeDetail
        },
        {
          path: '/area-code',
          name: 'Area Code',
          component: AreaCode
        },
        {
          path: '/ppn-tax',
          name: 'PPN Tax',
          component: PpnTax
        },
        {
          path: '/project',
          name: 'Project',
          component: Project,
        },
        {
          path: '/pra-project',
          name: 'Pra Project',
          component: PraProject,
        },
        {
          path: '/retail',
          name: 'Retail',
          component: Retail,
        },
        {
          path: '/prm',
          name: 'PRM',
          component: PRM,
        },
        {
          path: '/stock-warehouse',
          name: 'Stock Gudang',
          component: Stock,
        },
        {
          path: '/stock-sales',
          name: 'Stock Sales',
          component: StockSales,
        },
        {
          path: '/mutasi-stock',
          name: 'Mutasi Stock',
          component: MutasiStock,
        },
        {
          path: '/purchase-order',
          name: 'Purchase Order',
          component: PurchaseOrder,
        },
        {
          path: '/proforma-invoice-uang-muka',
          name: 'Proforma Invoice Uang Muka',
          component: ProformaInvoiceDownPayment,
        },
        {
          path: '/delivery-order',
          name: 'Delivery Order',
          component: DeliveryOrder,
        },
        {
          path: '/production-card',
          name: 'Production Card',
          component: ProductionCard,
        },
        {
          path: '/production-delivery-order',
          name: 'Production Delivery Order',
          component: ProductionDeliveryOrder,
        },
        {
          path: '/invoice',
          name: 'Invoice',
          component: Invoice,
        },
        {
          path: '/payment-invoice',
          name: 'Payment Invoice',
          component: PaymentInvoice,
        },
        {
          path: '/formula',
          name: 'Formula',
          component: Formula,
        },
        {
          path: '/request-material',
          name: 'Request Material',
          component: RequestMaterial,
        },
        {
          path: '/purchase-price',
          name: 'Purchase Item Price',
          component: PurchaseItemPrice,
        },
        {
          path: '/item-transfer',
          name: 'Item Transfer',
          component: ItemTransfer,
        },
        {
          path: '/coa',
          name: 'Chart Of Accounting',
          component: COA,
        },
        {
          path: '/bank-in',
          name: 'Bank Masuk',
          component: BankIn,
        },
        {
          path: '/cash-in',
          name: 'Kas Masuk',
          component: CashIn,
        },
        {
          path: '/cash-out',
          name: 'Kas Keluar',
          component: CashOut,
        },
        {
          path: '/bank-out',
          name: 'Bank Keluar',
          component: BankOut,
        },
        {
          path: '/trial-balance',
          name: 'Trial Balance',
          component: TrialBalance,
        },
        {
          path: '/ledger',
          name: 'Buku Besar',
          component: Ledger,
        },
        {
          path: '/balance',
          name: 'Neraca',
          component: Balance,
        },
        {
          path: '/general-journal',
          name: 'Jurnal Umum',
          component: GeneralJournal,
        },
        {
          path: '/adjustment-journal',
          name: 'Jurnal Adjustment',
          component: AdjustmentJournal,
        },
        {
          path: '/production-report',
          name: 'Report Production',
          component: ProductionReport,
        },
        {
          path: '/trial-balance-report',
          name: 'Trial Balance Report',
          component: TrialBalanceReport,
        },
        {
          path: '/ledger-report',
          name: 'Ledger Report',
          component: LedgerReport,
        },
        {
          path: '/purchasing-request-report',
          name: 'Report Purchasing Request',
          component: PurchasingRequestReport,
        },
        {
          path: '/purchasing-order-report',
          name: 'Report Purchasing Order',
          component: PurchasingOrderReport,
        },
        {
          path: '/purchasing-invoice-report',
          name: 'Report Purchasing Invoice',
          component: PurchasingInvoiceReport,
        },
        {
          path: '/purchasing-invoice-payment-report',
          name: 'Report Purchasing Invoice Payment',
          component: PurchasingInvoicePaymentReport,
        },
        {
          path: '/purchasing-delivery-order-report',
          name: 'Report Purchasing Delivery Order',
          component: PurchasingDeliveryOrderReport,
        },
        {
          path: '/transaction-purchase-order-report',
          name: 'Transaction Purchase Order Report',
          component: TransactionPurchaseOrderReport,
        },
        {
          path: '/transaction-delivery-order-report',
          name: 'Transaction Delivery Order Report',
          component: TransactionDeliveryOrderReport,
        },
        {
          path: '/transaction-invoice-report',
          name: 'Transaction Invoice Report',
          component: TransactionInvoiceReport,
        },
        {
          path: '/transaction-invoice-payment-report',
          name: 'Transaction Invoice Payment Report',
          component: TransactionInvoicePaymentReport,
        },
        {
          path: '/vendor',
          name: 'Vendor',
          component: Vendor,
        },
        {
          path: '/purchasing-invoice',
          name: 'Purchasing Invoice',
          component: PurchasingInvoice,
        },
        {
          path: '/request',
          name: 'Request',
          component: Request,
        },
        {
          path: '/order',
          name: 'Order',
          component: RequestOrder,
        },
        {
          path: '/purchasing-payment-invoice',
          name: 'Purchasing Payment Invoice',
          component: PurchasingPaymentInvoice,
        },
        {
          path: '/delivery',
          name: 'Delivery',
          component: Delivery,
        },
        {
          path: '/mutasi-saldo',
          name: 'Mutasi Saldo',
          component: MutasiSaldo,
        },
        {
          path: '/finance-approver',
          name: 'Finance Approver',
          component: FinanceApprover,
        },
        // {
        //   path: '/invoice-debit',
        //   name: 'Invoice Debit',
        //   component: InvoiceDebit,
        // },
        {
          path: '/technical-support-request',
          name: 'Technical Support Request',
          component: TechnicalSupportRequest,
        },
        {
          path: '/technical-support-visit',
          name: 'Technical Support Visit',
          component: TechnicalSupportVisit,
        },
        {
          path: '/technical-support-complain',
          name: 'Technical Support Complain',
          component: TechnicalSupportComplain,
        },
        {
          path: '/production-invoice',
          name: 'Production Invoice',
          component: ProductionInvoice,
        },
        {
          path: '/production-invoice-payment',
          name: 'Production Invoice Payment',
          component: ProductionInvoicePayment,
        },
        {
          path: '/booking-order',
          name: 'Booking Order',
          component: BookingOrder,
        },
        {
          path: '/booking-order-distributor',
          name: 'Booking Order Distributor',
          component: BookingOrderDistributor,
        },
        {
          path: '/minimal-stock-item',
          name: 'Minimal Stock Item',
          component: MinimalStockItem,
        },
        {
          path: '/general-ledger',
          name: 'Buku Besar General',
          component: GeneralLedger,
        },
        {
          path: '/forecasting-stock',
          name: 'Stock Forecasting',
          component: ForecastingStock,
        },
        // {
        //   path: '/commission/:id/:view',
        //   name: 'Komisi',
        //   component: Commission,
        // },
        {
          path: '/pra-project-approver',
          name: 'Pra Project Approver',
          component: PraProjectApprover,
        },
        {
          path: '/borrowing-tools',
          name: 'Peminjaman Alat',
          component: BorrowingTools,
        },
        {
          path: '/tax-invoice',
          name: 'Tax Invoice',
          component: TaxInvoice
        },
        {
          path: '/receivable-report',
          name: 'Report Piutang',
          component: ReceivableReport,
        },
        {
          path: '/debt-report',
          name: 'Report Hutang',
          component: DebtReport,
        },
        {
          path: '/forecast-report',
          name: 'Report Forecast',
          component: ForecastReport,
        },
        {
          path: '/forecast-other-report',
          name: 'Report Forecast Other',
          component: ForecastOtherReport,
        },
        {
          path: '/booking-order-report',
          name: 'Report Booking Order',
          component: BookingOrderReport,
        },
        {
          path: '/sales-report',
          name: 'Report Sales',
          component: SalesReport,
        },
        {
          path: '/mutasi-stock-report',
          name: 'Report Mutasi Stock',
          component: MutasiStockReport,
        },
        {
          path: '/item-first-in-first-out-mutation-report',
          name: 'Report Item First In First Out Mutation',
          component: ItemFirstInFirstOutMutationReport,
        },
        {
          path: '/tracking-purchase-order',
          name: 'Tracking Purchase Order',
          component: TrackingPurchaseOrder,
        },
        {
          path: '/commission-estimation',
          name: 'Estimasi Komisi',
          component: CommissionEstimation,
        },
        {
          path: '/commission-spec',
          name: 'Komisi Spek',
          component: CommissionSpec,
        },
        {
          path: '/commission',
          name: 'Komisi',
          component: Commission,
        },
        {
          path: '/commission-payment',
          name: 'Komisi Payment',
          component: CommissionPayment,
        },
        {
          path: '/commission-report',
          name: 'Report Komisi',
          component: CommissionReport,
        },
        {
          path: '/borrowing-tools-report',
          name: 'Report Peminjaman Alat',
          component: BorrowingToolsReport,
        },
      ]
    },
    {
      path: '/',
      redirect: '/project',
      name: 'Project',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/project/:formtype/:id/:view',
          name: 'Project Form',
          component: ProjectFormPage
        },
      ]
    },
    {
      path: '/',
      redirect: '/retail',
      name: 'Retail',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/retail/:formtype/:id/:view',
          name: 'Retail Form',
          component: RetailFormPage
        },
      ]
    },
    {
      path: '/',
      redirect: '/purchase-order',
      name: 'Purchase Order',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/purchase-order/:formtype/:id/:view/:reedit',
          name: 'Purchase Order Form',
          component: PurchaseOrderFormPage
        },
      ]
    },
    {
      path: '/',
      redirect: '/proforma-invoice-uang-muka',
      name: 'Proforma Invoice Uang Muka',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/proforma-invoice-uang-muka/:formtype/:id/:view',
          name: 'Proforma Invoice Uang Muka Form',
          component: ProformaInvoiceDownPaymentFormPage
        },
      ]
    },
    {
      path: '/',
      redirect: '/delivery-order',
      name: 'Delivery Order',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/delivery-order/:formtype/:id/:view',
          name: 'Delivery Order Form',
          component: DeliveryOrderDetail
        },
      ]
    },
    {
      path: '/',
      redirect: '/invoice',
      name: 'Invoice',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/invoice/:formtype/:id/:type/:view/:poid',
          name: 'Invoice Form',
          component: InvoiceForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/payment-invoice',
      name: 'Payment Invoice',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/payment-invoice-form/:formtype/:id/:view',
          name: 'Payment Invoice Form',
          component: PaymentInvoiceForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/bank-in',
      name: 'Bank Masuk',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/bank-in-form/:formtype/:id/:view/:type',
          name: 'Bank In Form',
          component: BankInForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/bank-out',
      name: 'Bank Keluar',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/bank-out-form/:formtype/:id/:view/:type',
          name: 'Bank Out Form',
          component: BankOutForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/cash-in',
      name: 'Kas Masuk',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/cash-in-form/:formtype/:id/:view',
          name: 'Cash In Form',
          component: CashInForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/cash-out',
      name: 'Kas Keluar',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/cash-out-form/:formtype/:id/:view',
          name: 'Cash Out Form',
          component: CashOutForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/trial-balance',
      name: 'Trial Balance',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/trial-balance-grid/:datefrom/:dateto/:settingid',
          name: 'Trial Balance Grid',
          component: TrialBalanceGrid
        },
      ]
    },
    {
      path: '/',
      redirect: '/trial-balance-report',
      name: 'Trial Balance Report',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/trial-balance-report-grid/:datefrom/:dateto/:settingid',
          name: 'Trial Balance Report Grid',
          component: TrialBalanceReportGrid
        },
      ]
    },
    {
      path: '/',
      redirect: '/balance',
      name: 'Neraca',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/balance-grid',
          name: 'Balance Grid',
          component: BalanceGrid
        },
      ]
    },
    {
      path: '/',
      redirect: '/general-journal',
      name: 'Jurnal Umum',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/general-journal-form/:formtype/:id/:view',
          name: 'General Journal Form',
          component: GeneralJournalForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/adjustment-journal',
      name: 'Jurnal Adjustment',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/adjustment-journal-form/:formtype/:id/:view',
          name: 'Adjustment Journal Form',
          component: AdjustmentJournalForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/request',
      name: 'Request',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/request-form/:formtype/:id/:view',
          name: 'Request Form',
          component: RequestForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/purchasing-invoice',
      name: 'Purchasing Invoice',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/purchasing-invoice-form/:formtype/:id/:view',
          name: 'Purchasing Invoice Form',
          component: PurchasingInvoiceForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/order',
      name: 'Order',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/order-form/:formtype/:id/:view',
          name: 'Order Form',
          component: RequestOrderForm
        },
      ]
    },    
    {
      path: '/',
      redirect: '/purchasing-payment-invoice',
      name: 'Purchasing Payment Invoice',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/purchasing-payment-invoice-form/:formtype/:id/:view',
          name: 'Purchasing Payment Invoice Form',
          component: PurchasingPaymentInvoiceForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/delivery',
      name: 'Delivery',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/delivery-form/:formtype/:id/:view',
          name: 'Delivery Form',
          component: DeliveryForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/technical-support-visit',
      name: 'Technical Support Visit',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/technical-support-visit-form/:formtype/:id/:view',
          name: 'Technical Support Visit Form',
          component: TechnicalSupportVisitForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/technical-support-complain',
      name: 'Technical Support Complain',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/technical-support-complain-form/:formtype/:id/:view',
          name: 'Technical Support Complain Form',
          component: TechnicalSupportComplainForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/borrowing-tools',
      name: 'Peminjaman Alat',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/borrowing-tools/:formtype/:id/:view',
          name: 'Borrowing Tools Form',
          component: BorrowingToolsDetail
        },
      ]
    },
    {
      path: '/',
      redirect: '/production-invoice',
      name: 'Production Invoice',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/production-invoice/:formtype/:id/:view',
          name: 'Production Invoice Form',
          component: ProductionInvoiceForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/production-invoice-payment',
      name: 'Production Invoice Payment',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/production-invoice-payment/:formtype/:id/:view',
          name: 'Production Invoice Payment Form',
          component: ProductionInvoicePaymentForm
        },
      ]
    },
    // {
    //   path: '/',
    //   redirect: '/invoice-debit',
    //   name: 'Invoice Debit',
    //   component: TheContainer, 
    //   meta: { requiresAuth: true },
    //   children: [
    //     {
    //       path: '/invoice-debit-form/:formtype/:id/:view',
    //       name: 'Invoice Debit Form',
    //       component: InvoiceDebitForm
    //     },
    //   ]
    // },
    {
      path: '/',
      redirect: '/production-card',
      name: 'Production Card',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/production-card-form/:formtype/:id/:view/:type/:status/:bookingitemid',
          name: 'Production Card Form',
          component: ProductionCardForm
        }, 
      ]
    },
    {
      path: '/',
      redirect: '/production-delivery-order',
      name: 'Production Delivery Order',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/production-delivery-order-form/:formtype/:id/:view/:type',
          name: 'Production Delivery Order Form',
          component: ProductionDeliveryOrderForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/booking-order',
      name: 'Booking Order',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/booking-purchase-order/:formtype/:boId/:poId/:view',
          name: 'Booking Purchase Order Form',
          component: BookingPurchaseOrderFormPage
        },
      ]
    },
    {
      path: '/',
      redirect: '/commission-estimation',
      name: 'Estimasi Komisi',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/commission-estimation/:formtype/:id/:view',
          name: 'Commission Estimation Form',
          component: CommissionEstimationForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/commission-spec',
      name: 'Komisi Spek',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/commission-spec/:formtype/:id/:view/:data',
          name: 'Commission Spec Form',
          component: CommissionSpecForm
        },
      ]
    },
    {
      path: '/',
      redirect: '/commission',
      name: 'Komisi',
      component: TheContainer, 
      meta: { requiresAuth: true },
      children: [
        {
          path: '/commission/:formtype/:id/:view/:data',
          name: 'Commission Form',
          component: CommissionForm
        },
      ]
    },

    // HTML Print    
    {
      path: '/adjustment-journal-html/:id',
      name: 'Adjustment Journal Html Template',
      component: AdjustmentJournalHtmlTemplate,
    },
    {
      path: '/general-journal-html/:id',
      name: 'General Journal Html Template',
      component: GeneralJournalHtmlTemplate,
    },
    {
      path: '/bank-in-html/:id',
      name: 'Bank In Html Template',
      component: BankInHtmlTemplate,
    },
    {
      path: '/bank-out-html/:id',
      name: 'Bank Out Html Template',
      component: BankOutHtmlTemplate,
    },
    {
      path: '/cash-in-html/:id',
      name: 'Cash In Html Template',
      component: CashInHtmlTemplate,
    },
    {
      path: '/cash-out-html/:id',
      name: 'Cash Out Html Template',
      component: CashOutHtmlTemplate,
    },
    {
      path: '/delivery-order-html/:id',
      name: 'Delivery Order Html Template',
      component: DeliveryOrderHtmlTemplate,
    },
    {
      path: '/ledger-html/:id/:startdate/:enddate',
      name: 'Ledger Html Template',
      component: LedgerHtmlTemplate,
    },
    {
      path: '/purchase-order-html/:id',
      name: 'Purchase Order Html Template',
      component: PurchaseOrderHtmlTemplate,
    },    
    {
      path: '/ledger-report-html/:id/:startdate/:enddate',
      name: 'Ledger Report Html Template',
      component: LedgerReportHtmlTemplate,
    },
    {
      path: '/invoice-html/:id/:type',
      name: 'Invoice Html Template',
      component: InvoiceHtmlTemplate,
    },
    {
      path: '/balance-html',
      name: 'Balance Html Template',
      component: BalanceHtmlTemplate,
    },
    {
      path: '/trial-balance-html/:datefrom/:dateto/:settingid',
      name: 'Trial Balance Html Template',
      component: TrialBalanceHtmlTemplate
    },
    {
      path: '/production-report-html/:startdate/:enddate',
      name: 'Production Report Html Template',
      component: ProductionReportHtmlTemplate,
    },    
    {
      path: '/purchasing-delivery-order-report-html/:startdate/:enddate',
      name: 'Purchasing Delivery Order Report Html Template',
      component: PurchasingDeliveryOrderReportHtmlTemplate,
    },
    {
      path: '/purchasing-invoice-payment-report-html/:startdate/:enddate',
      name: 'Purchasing Invoice Payment Report Html Template',
      component: PurchasingInvoicePaymentReportHtmlTemplate,
    },
    {
      path: '/purchasing-invoice-report-html/:startdate/:enddate',
      name: 'Purchasing Invoice Report Html Template',
      component: PurchasingInvoiceReportHtmlTemplate,
    },
    {
      path: '/purchasing-order-report-html/:startdate/:enddate',
      name: 'Purchasing Order Report Html Template',
      component: PurchasingOrderReportHtmlTemplate,
    },
    {
      path: '/purchasing-request-report-html/:startdate/:enddate',
      name: 'Purchasing Request Report Html Template',
      component: PurchasingRequestReportHtmlTemplate,
    },
    {
      path: '/trial-balance-report-html/:datefrom/:dateto/:settingid',
      name: 'Trial Balance Report Html Template',
      component: TrialBalanceReportHtmlTemplate
    },
    {
      path: '/transaction-purchase-order-report-html/:startdate/:enddate',
      name: 'Transaction Purchase Order Report Html Template',
      component: TransactionPurchaseOrderReportHtmlTemplate,
    },
    {
      path: '/transaction-invoice-report-html/:startdate/:enddate',
      name: 'Transaction Invoice Report Html Template',
      component: TransactionInvoiceReportHtmlTemplate,
    },
    {
      path: '/transaction-invoice-payment-report-html/:startdate/:enddate/:filterDate',
      name: 'Transaction Invoice Payment Report Html Template',
      component: TransactionInvoicePaymentReportHtmlTemplate,
    },
    {
      path: '/transaction-delivery-order-report-html/:startdate/:enddate',
      name: 'Transaction Delivery Order Report Html Template',
      component: TransactionDeliveryOrderReportHtmlTemplate,
    },
    {
      path: '/general-ledger-html/:startdate/:enddate',
      name: 'General Ledger Html Template',
      component: GeneralLedgerHtmlTemplate,
    },
    {
      path: '/request-order-html/:id',
      name: 'Request Order Html Template',
      component: RequestOrderHtmlTemplate,
    }, 
    {
      path: '/request-order-html-english/:id',
      name: 'Request Order English Html Template',
      component: RequestOrderHtmlEnglishTemplate,
    }, 
    {
      path: '/forecasting-stock-html/:id',
      name: 'Forecasting Stock Html Template',
      component: ForecastingStockHtml,
    },
    {
      path: '/finance-approver-html/:id/:invoicenumber/:npwp',
      name: 'Finance Approver Html Template',
      component: FinanceApproverHtml,
    },
    {
      path: '/sales-report-html/:startdate/:enddate',
      name: 'Sales Report Html Template',
      component: SalesReportHtmlTemplate,
    },
    {
      path: '/proforma-invoice-report-html/:id',
      name: 'Proforma Invoice Report Html Template',
      component: ProformaInvoiceHtmlTemplate,
    },
    {
      path: '/proforma-invoice-booking-report-html/:id',
      name: 'Proforma Invoice Booking Report Html Template',
      component: ProformaInvoiceBookingHtmlTemplate,
    },
    {
      path: '/proforma-invoice-uang-muka-report-html/:id',
      name: 'Proforma Invoice Uang Muka Report Html Template',
      component: ProformaInvoiceDownPaymentHtmlTemplate,
    },
  ]
}

export {router as default}